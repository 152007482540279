<template>
  <v-app>
    <div class="header">
      <img class="header_logo" src="@/assets/images/logo_default.svg" />
    </div>
    <div class="body">
      <img
        :src="require(`@/assets/images/intro/intro_back.jpg`)"
        style="position : absolute; width : 100%; height : 100%; z-index : 0; min-width : 1903px; max-height : 1200px;"
      />

      <v-form>
        <v-container>
          <div style="height : 150px"></div>
          <v-row style="height : 350px" class="banner">
            <v-col cols="12" sm="0" md="0" lg="0" xl="2"></v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="8">
              <img
                :src="require(`@/assets/images/intro/intro_left_01.png`)"
                @click="goHosp()"
              />
            </v-col>
          </v-row>
          <v-row style="height : 350px" class="banner">
            <v-col cols="12" sm="0" md="0" lg="0" xl="2"></v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="8">
              <img
                :src="require(`@/assets/images/intro/intro_right_01.png`)"
                @click="goRefer()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </v-app>
</template>

<script>
export default {
  methods: {
    goHosp() {
      this.$router.push("/asso/entMain").catch(()=>{});
    },
    goRefer() {
      this.$router.push("/").catch(()=>{});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/oto";
.modal-wrap {
  background-color: #146f83;
  width: 500px;
}

.banner {
  img {
    position: absolute;
    width: 100%;
    height: 300px;
    z-index: 0;
    min-width: 700px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) {
  .banner {
    position: relative;
    top: -200px;
    img {
      width: 80%;
      height: 280px;
    }
  }
}

@media screen and (max-width: 768px) {
  .banner {
    img {
      min-width: 0px !important;
      width: 500px !important;
      height: 200px !important;
    }
  }
}
@media screen and (max-width: 545px) {
  .banner {
    img {
      min-width: 0px !important;
      width: 300px !important;
      height: 100px !important;
    }
  }
}
</style>
